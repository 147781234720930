import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../../components/Loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class LoadingInterceptorService {

  activeRequests: number = 0;
  constructor(
        private loadingScreenService: LoaderService
    ) { }

intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.activeRequests === 0) {
        this.loadingScreenService.show();
    }

    this.activeRequests++;

    return next.handle(request).pipe(
        finalize(() => {
            this.activeRequests--;
            if (this.activeRequests === 0) {
                this.loadingScreenService.hide();
            }
        })
    )
};
}