import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/core/services/auth.services';

@Component({
  selector: 'mb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loading = false;
  isLoggedIn= false;
  isIframe = false;
  loggedIn: boolean=false;
  constructor(
    private _authService: MsalService,
    private _authservices: AuthService,
    private _router: Router
  ) {}
  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    //this.checkoutAccount();

        //   this._authService.handleRedirectObservable().pipe(
        //     concatMap(() => {           
        //         if (!this._authService.getAllAccounts().length) {
        //             return this._router.navigate(['dashboard']);
        //         }
        //         return of(true);
        //     }),
        //     catchError(() => console.log)
        // );
  }
  checkoutAccount() {
    this.isLoggedIn = this._authservices.isLoggedIn()
    if(!this.isLoggedIn)
    this._router.navigate(['login']);

  }

  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    if (isIE) {
      this._authService.loginRedirect();
    } else {
      this._authService.loginRedirect();
    }
  }

  logout() {
    this._authService.logout();
  }
}
