import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {throwError} from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ConfigService } from '../config.service';
import { compare } from 'fast-json-patch';
import { environment } from '../../../../environments/environment';
@Injectable()
export class PushService {

  private API_URL: string

  constructor(
    private http: HttpClient,
    private configService: ConfigService) {
    this.API_URL =  `${this.configService.apiUrl}webpush/subscriber`;//this.configService.get('API_URL')
  }

  urlBase64ToUint8Array(base64String:string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  addSubscriber(subscription:any, uid:string) {

    const url = `${this.API_URL}`;
    console.log('[Push Service] Adding subscriber')

    let body = {
      action: 'subscribe',
      subscription: subscription,
      uid: uid
    }

    return this.http
      .post(url, body).pipe(
        catchError(this.handleError)
      );

  }

  deleteSubscriber(subscription:any) {
    const url = `${this.configService.apiUrl}webpush/unsubscriber`;
    let body = {
      action: 'unsubscribe',
      subscription: subscription,
      itemid:'90'
    }
    let orgbody = {
      action: 'subscribe',
      itemid:'90'
    }
    const patch = compare(orgbody, body);

    return this.http
      .patch(url,JSON.stringify(patch),{  headers: { 'Content-Type': 'application/json-patch+json' ,'X-Version':environment.apiVersion} })
    .pipe(
         map(t => {
            return t;
        }
        ,(error: any) => {
    
            this.handleError(error);
            }
        ),
        catchError(this.handleError),
        finalize (() => {
          this.onEnd();
        })
      );
  }
  sendNotification(payload:any)
  {
    const url = `${this.configService.apiUrl}}webpush/notify`;
    return this.http
    .post(url, payload,{ headers: new HttpHeaders({'Content-Type': 'application/json' ,responseType:'text','X-Version':environment.apiVersion})
  }).pipe(
          map(t => {
            return t;
        }
        ,(error: any) => {

            this.handleError(error);
            }
        ),
        catchError(this.handleError),
        finalize (() => {
          this.onEnd();
        })
    );
  }
  count()
  {
    const url = `${this.configService.apiUrl}webpush/notify/count`;
    return this.http
    .get(url).pipe(
      catchError(this.handleError)
    );
  }
  private handleError(error: Response | any) {
    console.log(error)
    let errMsg: string;
    if (error instanceof Response) {
      errMsg = `${error.statusText || 'Network error'}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
  private onEnd(): void {
  }
}
