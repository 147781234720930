import {CardModule} from 'primeng/card';
import {ButtonModule} from 'primeng/button';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { NgModule } from '@angular/core';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {ToolbarModule} from 'primeng/toolbar';
import {FieldsetModule} from 'primeng/fieldset';
import { SliderModule } from 'primeng/slider';

const modules = [
  ToolbarModule,
  CardModule,
  ButtonModule,
  MessagesModule,
  MessageModule,
  TieredMenuModule,
  ToastModule,
  FieldsetModule,
  SliderModule
];

@NgModule({
  imports: [modules],
  exports: [ modules]
})
export class PrimeNGModule { }