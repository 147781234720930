<div class="loc-container"> 
  <span>{{location}}</span>
</div>

<div style="text-align: center;">
  <!-- <mat-slider style="text-align: center;" class="example-margin" [max]=stateMax min=0 step=1 thumbLabel=true
    [ngModel]="state" vertical=false></mat-slider> -->
    <p-slider [ngModel]="(ObservableSlider$|async)" [max]=stateMax [min]=0 [step]=1 ></p-slider>
</div>

<div style="text-align: center;height: 470px; z-index: 1000;" (click)="containter_click($event)" >
  <mat-button-toggle-group #group="matButtonToggleGroup" class="relay-radio-group">
    <mat-button-toggle value="Op" matTooltip="Op" [disabled]="isButtonsLocked" matTooltipPosition="right"
      aria-label="Op" alt="Op">
      <div #buttonUp style="display: inline-flex;"> <mat-icon>arrow_drop_up</mat-icon></div>
    </mat-button-toggle>
    <mat-button-toggle value="Neer" [disabled]="isButtonsLocked" matTooltip="Neer" matTooltipPosition="right"
      checked=true aria-label="Neer" alt="Neer">
      <div #buttonDown style="display: inline-flex;"> <mat-icon>arrow_drop_down</mat-icon></div>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
<div style="text-align: center;margin-top:-30px;">
  <button mat-mini-fab [color]="r1color">Aan</button>
  <button mat-mini-fab [color]="r2color">Neer</button>
  <div *ngIf="state > -1" class="mat-tab-label unselectable" style="position: relative">{{state}}</div>
  <div *ngIf="state===-1" class="mat-tab-label unselectable" style="position: relative">Scherm status ontvangen...</div>
  <div *ngIf="isConnecting$ | async">Verbinden met scherm</div>
  <div *ngIf="tokenExp">Nieuwe token ophalen</div>
  <div *ngIf="loginDisplay$|async">Aangemeld</div>
  <div *ngIf="loginDisplay$|async">Token geldig tot:<br/>{{datetext$|async}}</div>
</div>