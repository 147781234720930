import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import {  map, finalize } from 'rxjs/operators';
import { ConfigService } from '../config.service';
import { environment } from '../../../../environments/environment';
import { IRelay } from '../../model/relay.interface';

@Injectable()
export class DataService {
  private endpoint!: string;
  public isFetching!: Observable<boolean>;
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    ) {
      this.endpoint = this.configService.apiUrl;
  }
  dump(): Observable<IRelay[]> {
    const timestamp = new Date().getTime();
    
    return this.http.get<IRelay[]>(`${this.endpoint}relay/dump?t=${timestamp}`,{  headers: { 'X-Version':environment.apiVersion}})
    .pipe(
      // catchError(this.handleError),
      map((data: IRelay[]) => {
        this.onSuccess(data);
        return JSON.parse(JSON.stringify(data)) as IRelay[]
      },
      finalize (() => {
        this.onEnd()
      })
    )
    )
  }
  relayON(num:number): Observable<IRelay[]> {
    const timestamp = new Date().getTime();
    return this.http.get<IRelay[]>(`${this.endpoint}relay/L${num}?t=${timestamp}`,{  headers: { 'X-Version':environment.apiVersion}})
    .pipe(
      // catchError(this.handleError),
      map((data: IRelay[]) => {
        this.onSuccess(data);
        return JSON.parse(JSON.stringify(data)) as IRelay[]
      },
      finalize (() => {
        this.onEnd()
      })
    )
    )
  }
  relayOFF(num:number): Observable<IRelay[]> {
    const timestamp = new Date().getTime();
    return this.http.get<IRelay[]>(`${this.endpoint}relay/D${num}?t=${timestamp}`,{  headers: { 'X-Version':environment.apiVersion}})
    .pipe(
      // catchError(this.handleError),
      map((data: IRelay[]) => {
       this.onSuccess(data);
        return JSON.parse(JSON.stringify(data)) as IRelay[]
      },
      finalize (() => {
        this.onEnd()
      })
    )
    )
  }

  private onSuccess(res: IRelay[]): void {
  }
  private onEnd(): void {
  }
  private handleError(error: Response | any) {
      let errMsg: string;
      if (error instanceof Response) {
        const body = error.json() || '';
        const err = body || JSON.stringify(body);
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
      } else {
        errMsg = error.message ? error.message : error.toString();
      }
;
    }
  }

