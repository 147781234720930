import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../../../environments/environment';
// import { ApiTokenService } from '../apitoken/api-token.service';
// import { AuthService } from '../auth.services';
// import { IApiState } from '../apitoken/Iapistate';
import { IRelay } from '../../model/relay.interface';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  connection!: signalR.HubConnection;
  hubLink!: BehaviorSubject<IRelay|null>;
  hubPing!: BehaviorSubject<string|null>;
  private isConnectedSubject = new Subject<boolean>();
  public  ObservableConnected$!:Observable<boolean>;
  data!: IRelay;
  isInit:boolean= false;
  state!: signalR.HubConnectionState;

  constructor() {
    this.ObservableConnected$ = this.isConnectedSubject.asObservable();
    // this._apiTokenService.apiAsToken$.subscribe((role:IApiState) => {
    //   if(!this.isInit&& role.hasToken)
    //    this.initiateSignalrConnection(role.token)
    // });
    this.hubLink = new BehaviorSubject<IRelay|null>(null);
    this.hubPing = new BehaviorSubject<string|null>(null);
  }

  public start(): Promise<void>{
       return   new Promise((resolve, reject) => { resolve();})
  }

  // Establish a connection to the SignalR server hub
  public initiateSignalrConnection(token:string): Promise<void>{
    this.isInit =true;
    const url =`https://${environment.domainapi}${environment.domainapiAppPath}${environment.signalrHubUrl}`
    return new Promise((resolve, reject) => {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(url, {skipNegotiation: true,transport: signalR.HttpTransportType.WebSockets, accessTokenFactory: () => { return token; } })
        .withAutomaticReconnect()
        .configureLogging(!environment.production?signalR.LogLevel.Information:signalR.LogLevel.None)
        .build();

      this.connection
        .start()
        .then(() => {
          this.startStreaming();
          !environment.production?console.log(`SignalR connection success! connectionId: ${this.connection.connectionId} `):null;
          this.isConnectedSubject.next(true);
          resolve();
        })
        .catch((error) => {
          console.log(`SignalR connection error: ${error}`);
        });

        this.state = this.connection.state;

        this.connection.onreconnected(connectionId => {
          this.isConnectedSubject.next(true);
          !environment.production? console.log(`SignalR onreconnected success! connectionId: ${connectionId} `):null;
          this.startStreaming()
        });
    });
  }

  public startStreaming() {
    this.connection.on('ping',data=>{
      this.hubPing.next(data);
    });
    this.connection.on('relays',data=>{
      this.hubLink.next(data);
    });

  this.connection.stream("realtimeData").subscribe({
    next: (model) => {
      if(model!== null)
      {
        if(model.relays!==null)
        this.hubLink.next(model?.relays);
      }
    },
    error: () => { },
    complete: () => { }
  });
 }
 public stopSignalrConnection()
 {
   if(this.connection!==undefined)
   this.connection.stop()
 }
}
