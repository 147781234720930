import { Injectable } from "@angular/core";
import { User } from "../model/user.data";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { filter } from "rxjs";
import { EventMessage, EventType } from "@azure/msal-browser";
@Injectable()
export class AuthService {
  user: User = {
    displayName: "",
    roles: [],
    groupsIds:[]
  };
    private name!:string;
    hasSubscrition:boolean=false;
  loginDisplay: boolean=false;
    constructor(
       private _authService: MsalService,
       private msalBroadcastService: MsalBroadcastService,
       ) {
        this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => {return (msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS|| msg.eventType === EventType.LOGIN_SUCCESS)}),
        )
        .subscribe((e:any) => {
          if(e!=null && e.payload.account!=null)
          {
            this.name = e.payload.account.name;
            this.loginDisplay =true;
            this._authService.instance.setActiveAccount(e.payload.account);
          }
        });
     }

     public isLoggedIn():boolean {
      return this._authService.instance.getAllAccounts().length > 0;
    }

   public signout():void {
    this._authService.logout()
   }

   public startAuthentication():any {
      this._authService.loginRedirect();
   }
   public getUserName():string{
    if(!!this._authService.instance.getActiveAccount() && this._authService.instance.getActiveAccount()?.username!==undefined)
    {
      const name = this._authService.instance.getActiveAccount()?.username;
        return name==undefined?"":name;
    }
    else
    return "?";
   }


   public getName(): string {

     if(this.name!==undefined)
     {
       let n:string[]  = this.name.split(', ');
       if(n[1]==undefined)
        this.name= n[0];
        else
        this.name= n[1] +' '+ n[0];
        return  this.name;
      }
      else
      return "";
     }
   public getNovaId(): string {
    if(!!this._authService.instance.getActiveAccount())
    {
     let naam = this._authService.instance.getActiveAccount()?.name;
     if(naam!==undefined)
     {
      return naam.indexOf('@')!==-1 ? naam.split('@')[0]:naam;
     }
     else
       return "";
     }
     else return "";
  }
}
