<div [title]="title" style="display:inline;direction:ltr">
<svg role="img" aria-hidden="true" focusable="false" class="svg-inline--fa fa-signal fa-w-20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-signal fa-w-20"  *ngIf="signal$ | async as signal">
    <defs>
       <linearGradient id="5">
        <stop offset="0%"  stop-color="#000000"/>
        <stop offset="100%" stop-color="#000000"/>
        <stop offset="100%" style="stop-color:#FFFFFF;stop-opacity:1" />
        </linearGradient>
        <linearGradient id="1" x1="0%" y1="0%" x2="20%" y2="0%">
            <stop offset="0%"  stop-color="#FFFFFF"/>
            <stop offset="100%" stop-color="#008f91"/>
            <stop offset="100%" style="stop-color:#008f91;stop-opacity:1" />
        </linearGradient>
        <linearGradient id="2" x1="0%" y1="0%" x2="40%" y2="0%">
            <stop offset="0%"  stop-color="#FFFFFF"/>
            <stop offset="100%" stop-color="#FFFFFF"/>
            <stop offset="100%" style="stop-color:#008f91;stop-opacity:1" />
        </linearGradient>
        <linearGradient id="3" x1="0%" y1="0%" x2="60%" y2="0%">
            <stop offset="0%"  stop-color="#FFFFFF"/>
            <stop offset="100%" stop-color="#FFFFFF"/>
            <stop offset="100%" style="stop-color:#008f91;stop-opacity:1" />
        </linearGradient>
        <linearGradient id="4" x1="0%" y1="0%" x2="80%" y2="0%">
            <stop offset="0%"  stop-color="#000000"/>
            <stop offset="100%" stop-color="#000000"/>
            <stop offset="100%" style="stop-color:#FFFFFF;stop-opacity:1" />
        </linearGradient>
    </defs>
    <g>
    <path *ngIf="online" d="m220,289l-48,0c-8.84,0 -16,7.16 -16,16l0,192c0,8.84 7.16,16 16,16l48,0c8.84,0 16,-7.16 16,-16l0,-192c0,-8.84 -7.16,-16 -16,-16zm-128,96l-48,0c-8.84,0 -16,7.16 -16,16l0,96c0,8.84 7.16,16 16,16l48,0c8.84,0 16,-7.16 16,-16l0,-96c0,-8.84 -7.16,-16 -16,-16zm256,-192l-48,0c-8.84,0 -16,7.16 -16,16l0,288c0,8.84 7.16,16 16,16l48,0c8.84,0 16,-7.16 16,-16l0,-288c0,-8.84 -7.16,-16 -16,-16zm128,-96l-48,0c-8.84,0 -16,7.16 -16,16l0,384c0,8.84 7.16,16 16,16l48,0c8.84,0 16,-7.16 16,-16l0,-384c0,-8.84 -7.16,-16 -16,-16zm128,-96l-48,0c-8.84,0 -16,7.16 -16,16l0,480c0,8.84 7.16,16 16,16l48,0c8.84,0 16,-7.16 16,-16l0,-480c0,-8.84 -7.16,-16 -16,-16z" 
    [attr.fill]="absoluteRef(signal)"
    stroke="gray"
    stroke-linecap="round" stroke-width="10"  fill="none"
    />
    <path *ngIf="!online" fill="currentColor" d="M633.82 458.1L616 444.33V16c0-8.84-7.16-16-16-16h-48c-8.84 0-16 7.16-16 16v366.5l-48-37.1V112c0-8.84-7.16-16-16-16h-48c-8.84 0-16 7.16-16 16v171.57l-48-37.1V208c0-8.84-7.16-16-16-16h-48c-1.82 0-3.5.48-5.13 1.04L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.36 454.73c1.22.95 2.04 2.18 2.9 3.37H600c.73 0 1.35-.32 2.06-.42 5.51.8 11.27-1.05 14.93-5.77l19.64-25.27c5.43-6.96 4.17-17.01-2.81-22.44zM88 384H40c-8.84 0-16 7.16-16 16v96c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16v-96c0-8.84-7.16-16-16-16zm80-96c-8.84 0-16 7.16-16 16v192c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16V309.32L204.41 288H168zm112 208c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16v-87.75l-80-61.83V496zm128 0c0 8.84 7.16 16 16 16h48c5.49 0 10.1-2.94 12.98-7.15L408 445.35V496z"></path>    
    </g>
</svg>
</div>