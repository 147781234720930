
<div style="margin: 100px;z-index: 100;"><h3>Login</h3>
<div *ngIf="!isLoggedIn">
    <p>Klik op de knop hieronder om in te loggen met je account</p>
    <button pButton (click)="login()" label="Inloggen" title="Inloggen"></button>
</div>

<div *ngIf="isLoggedIn">
    <button pButton (click)="logout()" label="Logout" title="Logout"></button>
</div>
</div>
