import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { LoaderService } from './loader.service';
import { Subscription } from 'rxjs';
import { ILoaderState } from './ILoader';

@Component({
    selector: 'angular-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.css']
})
export class LoaderComponent implements OnInit, OnDestroy {

    show = false;
    private subscription!: Subscription;

    constructor(
      private cdRef : ChangeDetectorRef,
        private loaderService: LoaderService
    ) { }

    ngOnInit() {
        this.subscription = this.loaderService.loaderState$
            .subscribe((state: ILoaderState) => {

                if (state.show != this.show) {
                  this.show = state.show;
                  this.cdRef.detectChanges();
                }
            });
    }


    ngOnDestroy() {
        if(this.subscription)
        this.subscription.unsubscribe();
    }
}

