export const environment = {
  title:'Zonnescherm afstandsbediening',
  production: true,
  domain:'zonnescherm.0251.eu',
  domainapi:'zonnescherm.0251.eu',
  domainapiAppPlusVersionPath:'/api/v1/',
  domainapiVersionPath:'/v1/',
  domainapiAppPath:'/api',
  apiVersion:'v1',
  signalrHubUrl:'/datapipe',
  config:{
    roloutTime43: '43',
    roloutTime21: '21',
    VAPID_PUBLIC_KEY:"BGnIz2J1YOs-cRL9RLkh04kgfQEbEHivwtKW9vO407py9pliDgfqrCohxy2a2t_BHZmzEs1hrVUodROJxOjB2iA",
    API_URL: "https://webpushapi20180705020622.azurewebsites.net/api/webpush"
  },
  appInsights:{instrumentationKey:'4cbf9b1a-f65d-4e39-8bbd-5e1eb77a1ef6'},
  serviceWorkerScript: '/sw-master.js',
  auth: {
    clientId: "10d16156-8c95-46c3-bb5d-188b9ac9c9a2",
    authority: "https://login.microsoftonline.com/f81f94de-90a0-4b51-b087-5573a4cb195c",
    validateAuthority: true,
    redirectUri: "https://zonnescherm.0251.eu/dashboard",
    postLogoutRedirectUri: "https://zonnescherm.0251.eu",
    navigateToLoginRequestUrl: true,
    tenantId:"f81f94de-90a0-4b51-b087-5573a4cb195c"
  },
  cache: {
    cacheLocation: "localStorage"
  },
  system: {
    loadFrameTimeout: 10000,
  },
  scopes: {
    loginRequest: [ "openid", "profile" ]
  },
  resources: {
    zonneschermApi: {
      resourceWs: "/api/datepipe*",
      resourceUri: "https://zonnescherm.0251.eu/api/v1",
      resourceScope: "api://6aeaffe9-8edb-461b-a1db-514a73be7713/user_impersonation"
    }
  },
  groups: {
    groupAdmin: "a1d3795b-684d-4e58-a978-8733281f32cf",
    groupTester: "9f63beca-867c-4538-bd43-4f64a5bcd6e4"
  },
  roles: {
    roleAdmin: "Admin",
    roleTester: "Tester"
  }
};
