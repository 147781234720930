import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list'
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {MatButtonModule} from '@angular/material/button';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faDownload,faSync, faBell,faBellSlash,faInfo,faEraser ,faSignal} from '@fortawesome/free-solid-svg-icons';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
// import { MatSliderModule } from '@angular/material/slider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PrimeNGModule } from './primeng.modules';
import { PreventDoubleClickDirective } from '../directive/preventdouble-click.directive';
const modules = [
    FormsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatIconModule,
    // MatSliderModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatListModule,
    MatSidenavModule,
   MatToolbarModule,
    FontAwesomeModule,
    PrimeNGModule
  ];
@NgModule({
    imports: [modules],
    exports: [ modules,PreventDoubleClickDirective],
    declarations: [PreventDoubleClickDirective],
    providers:[],
  })
  export class SharedModule { 
    constructor(library: FaIconLibrary) {
      // Add an icon to the library for convenient access in other components
        library.addIcons(faSync,faDownload,faBell,faBellSlash,faInfo,faEraser,faSignal);
      }
  }
