import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';
import {  NEVER } from 'rxjs';
import { Network } from '@ngx-pwa/offline';
import { LoaderService } from '../components/Loader/loader.service';
import { MessageService } from 'primeng/api';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private _router:Router,
       public snackBar: MatSnackBar,
       private _messageService: MessageService,
       private network: Network,
       private _loaderService: LoaderService
       ){}
      intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
          // install an error handler
          return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
              this._loaderService.hide();
              if(!this.network.online)
              {
                return next.handle(req);
              }
              if (err.error instanceof Error) {
                  // A client-side or network error occurred. Handle it accordingly.
    
              } else{
                  // The backend returned an unsuccessful response code.
                  // The response body may contain clues as to what went wrong,
                  if (err instanceof HttpErrorResponse) {
                    if (err.status === 504) {
                      let snackBarRef = this.snackBar.open('Application interface (API) is niet beschikbaar. Heb je wel een internetverbinding? Als het antwoord Ja is dan is de API niet beschikbaar. Gegevens kunnen niet vernieuwd worden.', 'X');
                      snackBarRef.onAction().subscribe(() => {
                          snackBarRef.dismiss();
  
                      });
                      return NEVER;
                    }
                    else if (err.status === 403) {
                      let snackBarRef = this.snackBar.open(`Je hebt niet voldoende rechten om gevens te benaderen via deze url. ${err.message}`, 'X');
                      snackBarRef.onAction().subscribe(() => {
                          snackBarRef.dismiss();
  
                      });
                      return NEVER;
                    }
                    if (err.status === 401) {
                      if(err.url && err.url.indexOf('api')!==-1){
                        console.log("get refesh toke");
  
                      }
                     this._router.navigate(['login']);
                    }
                    else if (err.status === 404 && err.url && err.url.indexOf('api')!==-1) {
                      return NEVER;
                    }
                    else if (err.status === 404&& err.url  && err.url.indexOf('api')==-1) {
                      this._router.navigate(['404']);
                    }
                    else if  (err.status === 409) {
                      let snackBarRef = this.snackBar.open('Je hebt je al aangemeld.', 'X');
                      snackBarRef.onAction().subscribe(() => {
                          snackBarRef.dismiss();
                      });
                    }
                    else if (err.status === 500) {
                    }

                    else{
                        let snackBarRef = this.snackBar.open(err && err.message ? err.message : '', 'X');
                        snackBarRef.onAction().subscribe(() => {
                            snackBarRef.dismiss();
                        });
                    }
                  }
              }
              let errormessage:string='';
              let errortype:string='lezen uit';
  
              if( req.url.indexOf('-api-')!=-1)
              {
                switch (req.method) {
                  case 'PATCH':
                    errortype='bijwerken in'
                    break;
                  case 'DELETE':
                    errortype='verwijderen uit'
                    break;
                  default:
                    break;
                }
                errormessage = `Kan de gevens niet ${errortype} de database.`;
              }
              this._messageService.add({key: 'saveupdate', sticky: true, severity:'error', summary:`Opps.`, detail:`Opps, er is iets misgegaan.\n\n\n${errormessage}\n\n\nURL:\n${req.url} \n\nType:\n${req.method}`});
              return NEVER;
          })
          )
      }
  }
