import { Injectable } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';

@Injectable()
export class ConfigService {
  private _config:any = environment.config;
  snackConfig = new MatSnackBarConfig();
  apiUrl = `https://${environment.domainapi}${environment.domainapiAppPlusVersionPath}`;//`https://${environment.domainapi}/api2/v1/`;//`https://${environment.domainapi}/api/`;// 'https://koekoek.0251.eu/api/relay';
  roloutTime:number =21;//Seconden
  constructor() {
    this.snackConfig.duration = 2500;
    this.snackConfig.panelClass = ['snack'];
   }
  get(key:any){
    return this._config[key];
  }
}
