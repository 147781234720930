
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {  map, catchError, finalize} from 'rxjs/operators';
import { DateConvert } from 'src/app/helper/date-convert';
import {throwError} from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { Resource } from '../../model/resource';
import { QueryOptions } from '../query.options';



export class ResourceService<T extends Resource> {
  constructor(
      private httpClient: HttpClient,
      private url: string,
      private _alertServices:AlertService,
      private endpoint: string
      ) {}

    public create(item: T): Observable<T> {
      return this.httpClient
        .post<T>(`${this.url}${this.endpoint}`, JSON.stringify(item),{  headers: { 'Content-Type': 'application/json' ,'X-Version':environment.apiVersion}})
        .pipe(
          catchError(this.handleError),
          map(data => DateConvert.Deserialize(JSON.stringify(data)) as T),
          finalize (() => {
            this.onEnd();
          })
        )
    }
    // public update(item: any, id:number): Observable<T> {
    //   return this.httpClient
    //     .patch<T>(`${this.url}${this.endpoint}?id=${id}&type=2`,JSON.stringify(item)
    //      ).pipe(
    //       map(data => DateConvert.Deserialize(JSON.stringify(data)) as T),
    //       catchError(this.handleError)
    //       );
    // }
    public update(item: any,queryOptions: QueryOptions): Observable<T> {
      return this.httpClient
        .patch<T>(`${this.url}${this.endpoint}${queryOptions.toQueryString()}`,JSON.stringify(item),{  headers: { 'Content-Type': 'application/json-patch+json' ,'X-Version':environment.apiVersion} }
         ).pipe(
          catchError(this.handleError),
          map(data => DateConvert.Deserialize(JSON.stringify(data)) as T),
          finalize (() => {
            this.onEnd();
          })
          );
    }

    read(id: number): Observable<T[]> {
      return this.httpClient
        .get<T[]>(`${this.url}${this.endpoint}/${id}`).pipe(
          catchError(this.handleError),
          map((data) => {
        //  const  x=  data as T[];
        //  let f = this.createT("") ;

        //  console.log(f.name)
            //this.setState({ data }, OrdersStoreActions.GetOrders);
             return DateConvert.Deserialize(JSON.stringify(data)) as T[]
          }),
          finalize (() => {
            this.onEnd();
          })
        )
      }
      createT( x : new () => T ) {
        return  x.name; // succeeds
    }
    // readold(creboId: number,cohortId: number,leerwegId: number,locatieId: number): Observable<T> {
    //   return this.httpClient
    //     .get(`${this.url}${this.endpoint}/${creboId}/${cohortId}/${leerwegId}/${locatieId}`).pipe(
    //       map((data: any) => DateConvert.Deserialize(JSON.stringify(data)) as T),
    //       catchError(this.handleError)
    //     )
    // }

    list(queryOptions: QueryOptions): Observable<T[]> {
      return this.httpClient
        .get<T[]>(`${this.url}${this.endpoint}${queryOptions.toQueryString()}`).pipe(
          catchError(this.handleError),
          map(data => {
            return JSON.parse(JSON.stringify(data)) as T[]
          },(error: any) => {
              this._alertServices.error(error);
              this.onError(error);
              }
          ),
          finalize (() => {
              this.onEnd();
          })
        )
    }
    listByPath(queryOptions: QueryOptions): Observable<T[]> {
      return this.httpClient
        .get<T[]>(`${this.url}${this.endpoint}${queryOptions.toPathString()}`).pipe(
          catchError(this.handleError),
          map(data=> {
            return JSON.parse(JSON.stringify(data)) as T[]
          },(error: any) => {
              this._alertServices.error(error);
              this.onError(error);
              }
          ),
          finalize (() => {
              this.onEnd();
          })
        )
    }

    public createWidthOptions(item: T,queryOptions: QueryOptions): Observable<T> {
      return this.httpClient
        .post<T>(`${this.url}${this.endpoint}${queryOptions.toQueryString()}`, JSON.stringify(item))
        .pipe(
          catchError(this.handleError),
          map(data => DateConvert.Deserialize(JSON.stringify(data)) as T),
          finalize (() => {
            this.onEnd();
          })
        )
    }

    delete(id: number) {
      return this.httpClient
        .delete(`${this.url}${this.endpoint}/${id}`,
        {  headers: { 'Content-Type': 'application/json' ,'X-Version':environment.apiVersion} }).pipe(
          catchError(this.handleError),
          finalize (() => {
            this.onEnd();
        })
        );
    }
    private onSuccess(res: Response): void {
      console.log('Request successful');
    }

    private onError(res: Response): void {
        console.log('Error, status code: ' + res.status);
    }

    private onEnd(): void {
    }

    // private showLoader(): void {
    //    this._loaderService.show();
    // }

    // private hideLoader(): void {
    //    this._loaderService.hide();
    // }

    // private convertData(data: any): T[] {
    //   return data.map(item => this.serializer.fromJson(item));
    // }

    private handleError(error: Response | any) {
      let errMsg: string="????";
      if (error instanceof Response) {
        const body = error.json() || '';
        const err = body || JSON.stringify(body);
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
      } else {
        errMsg = error.message ? error.message : error.toString();
      }
      return throwError(errMsg);
    }
  }
