import { MsalInterceptorConfiguration, ProtectedResourceScopes } from "@azure/msal-angular";
import { InteractionType } from "@azure/msal-browser";
import { environment } from "../../../environments/environment";

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
     const protectedResourceMap = new Map<string,  Array<string | ProtectedResourceScopes> | null>([
      [ environment.resources.zonneschermApi.resourceWs+'/ws*',null],
    
      [
        environment.resources.zonneschermApi.resourceWs,[
          {
              httpMethod: "POST",
              scopes: [ environment.resources.zonneschermApi.resourceScope]
          },
          {
              httpMethod: "PATCH",
              scopes: [ environment.resources.zonneschermApi.resourceScope]
          },
          {
              httpMethod: "GET",
              scopes: [ environment.resources.zonneschermApi.resourceScope]
          }
          ]
      ],
      [
        environment.resources.zonneschermApi.resourceUri,[
          {
              httpMethod: "POST",
              scopes: [ environment.resources.zonneschermApi.resourceScope]
          },
          {
              httpMethod: "PATCH",
              scopes: [ environment.resources.zonneschermApi.resourceScope]
          },
          {
              httpMethod: "GET",
              scopes: [ environment.resources.zonneschermApi.resourceScope]
          }
          ]
      ]
     ]);

  
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap:  protectedResourceMap,
    };
  }