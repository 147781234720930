import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/core/services/auth.services';
@Component({
  selector: 'mb-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  isAuthenticated = false;
  username!: string;

  constructor(
    private _authService: MsalService,
    private _authservices: AuthService,
  ) {
    this.isAuthenticated = this._authservices.isLoggedIn();
  }

  ngOnInit() {
    if (this.isAuthenticated) {
      this.username = this._authservices.getName();
      this._authService.logout();
    }
  }
  logout() {
    this._authService.logout();
  }
}
