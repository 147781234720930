import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard.component';
import { MsalGuard } from '@azure/msal-angular';
import { NoWebpushComponent } from './controls/no-webpush/no-webpush.component';
import { LoginComponent } from './components/authorization/login/login.component';
import { LogoutComponent } from './components/authorization/logout/logout.component';
import { PageNotFoundComponent } from './pages/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: 'dashboard', component: DashboardComponent, canActivate: [MsalGuard]},
  {
    path: 'controls',
    data: { preload: false, delay: true },
    loadChildren: () => import('./controls/controls.module').then(m => m.ControlsModule)
  },
  { path:'nowebpush', component:NoWebpushComponent},

  { path: 'login', component: LoginComponent},
  { path: 'logout', component: LogoutComponent},
  { path: '404', component: PageNotFoundComponent},
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
