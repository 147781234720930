import { Component, OnInit, OnDestroy, ViewChild, Renderer2, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ConfigService } from '../core/services/config.service';
import { BehaviorSubject, debounceTime, delay, filter, interval, map, Observable, of, Subject, Subscription } from 'rxjs';
import { AuthService } from '../core/services/auth.services';
import {isMobile} from '../helper/ismobile';
import { JwtHelperService } from '../core/jwthelper.service';
import { ApiTokenService } from '../core/services/apitoken/api-token.service';
import { IApiState } from '../core/services/apitoken/Iapistate';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SignalrService } from '../core/services/signalr/SignalrService';
import { DataService } from '../core/services/api/data.service';
import { IRelay } from '../core/model/relay.interface';
import { LoaderService } from './Loader/loader.service';
import {  NavigationEnd,  Router } from '@angular/router';
import {  HubConnectionState } from '@microsoft/signalr';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { DatePipe } from '@angular/common';
import { IJWT } from '../core/model/jwt.interface';
export enum RelayState {
  On = 'on',
  Off='off',
}
@Component({
  selector: 'mb-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit,OnDestroy,AfterViewInit {
  @ViewChild('buttonDown' , {static:true}) btnDown!: ElementRef;
  @ViewChild('buttonUp' , {static:true}) btnUp!: ElementRef;
  public state:number=-1;
  public stateMax:number=21;
  private stateMen:number=0;
  r1color:string="black";
  r2color:string="black";
  globalInstanceDownUp!: () => void;
  globalInstanceDown!: () => void;
  timerIntervalUp!: Subscription;
  timerIntervalDown!: Subscription;
  globalInstanceUpUp!: () => void;
  globalInstanceUpDown!: () => void;
  ip: string="";
  isButtonPressed: boolean=false;
  isButtonsLocked: boolean=true;
  location: string="?";
  email: any;

  private SubjectR1 = new BehaviorSubject<boolean>(false);
  private SubjectR2 = new BehaviorSubject<boolean>(false);
  private SubjectR3 = new Subject<boolean>();
  private SubjectR4 = new Subject<boolean>();
  private SubjectR5 = new Subject<boolean>();
  private SubjectR6 = new Subject<boolean>();
  private SubjectR7 = new Subject<boolean>();
  private SubjectR8 = new Subject<boolean>();
  private SubjectSlider = new BehaviorSubject<number>(0);
  private  ObservableR1$!:Observable<boolean>;
  private  ObservableR2$!:Observable<boolean>;
  private  ObservableR3$!:Observable<boolean>;
  private  ObservableR4$!:Observable<boolean>;
  private  ObservableR5$!:Observable<boolean>;
  private  ObservableR6$!:Observable<boolean>;
  private  ObservableR7$!:Observable<boolean>;
  private  ObservableR8$!:Observable<boolean>;
  public  ObservableSlider$!:Observable<number>;
  loginDisplay$: Observable<boolean> = of(false);
  datetext$: Observable<string> = of("?");
  opNeer: boolean=true;
  aanUit: boolean|null=null;
  bit1: boolean=false;
  bit2:  boolean|null=null;
  bit3:  boolean|null=null;
  bit4:  boolean|null=null;
  bit5:  boolean|null=null;
  bit6:  boolean|null=null;
  isConnecting$ = new BehaviorSubject<boolean>(true);
  tokenExp: boolean=false;
  loginDisplay: boolean=false;
  tokenExpDate!: Date | null;
  firstcall:boolean=true;
  
  constructor(
    private _router: Router,
    private cdRef:ChangeDetectorRef,
    private _signalrService:SignalrService,
    private _authservices: AuthService,
    private _loaderservices: LoaderService,
     private _jwthelperService:JwtHelperService,
     private _apiTokenService:ApiTokenService,
    private renderer: Renderer2,
    public snackBar: MatSnackBar,
    private _msalBroadcastService: MsalBroadcastService,
    private _authService: MsalService,
    private _dataService: DataService,
    private _datePipe: DatePipe,
    private _configService: ConfigService) {
      this.ObservableR1$ = this.SubjectR1.asObservable();
      this.ObservableR2$ = this.SubjectR2.asObservable();
      this.ObservableR3$ = this.SubjectR3.asObservable();
      this.ObservableR4$ = this.SubjectR4.asObservable();
      this.ObservableR5$ = this.SubjectR5.asObservable();
      this.ObservableR6$ = this.SubjectR6.asObservable();
      this.ObservableR7$ = this.SubjectR7.asObservable();
      this.ObservableR8$ = this.SubjectR8.asObservable();
      this.ObservableSlider$ = this.SubjectSlider.pipe(debounceTime(500));
      this._loaderservices.show();
    // Scherm aanzetten
      // Aan uit
      this.ObservableR1$.subscribe((r1:boolean) => {
        // Kleuren van de aan uit knoppen bepalen
        r1 ? this.r1color="warn" : this.r1color="primary";
        //this.cdRef.detectChanges();
        this.aanUit = r1;
      });
     // Neer/ Op     
     this.ObservableR2$.subscribe((r2:boolean) => {
      // Kleuren van de aan uit knoppen bepalen
        r2 ? this.r2color="warn" : this.r2color="primary";
        //this.cdRef.detectChanges();
        
        this.opNeer= r2;
      });

      // Bit 1
     this.ObservableR3$.subscribe((b:boolean) => {
        this.bit1 = b;
        this.ConvertInComingDataSignalR();
      });
      // Bit 2
     this.ObservableR4$.subscribe((b:boolean) => {
        this.bit2 = b;
        this.ConvertInComingDataSignalR();
      });
      // Bit 3
     this.ObservableR5$.subscribe((b:boolean) => {
        this.bit3 = b;
        this.ConvertInComingDataSignalR();
      });
      // Bit 4
     this.ObservableR6$.subscribe((b:boolean) => {
        this.bit4 = b;
        this.ConvertInComingDataSignalR();
      });
      // Bit 5
     this.ObservableR7$.subscribe((b:boolean) => {
        this.bit5 = b;
        this.ConvertInComingDataSignalR();
      });
      // Bit 6
     this.ObservableR8$.subscribe((b:boolean) => {
        this.bit6 = b;
        this.ConvertInComingDataSignalR();
      });
      this._signalrService.ObservableConnected$.subscribe((b:boolean) => {
        if(b && this._signalrService.state === HubConnectionState.Connected)
        {
         
          // Current Status of screen
          this._signalrService.connection.invoke("Relays","DUMP");

         
          this.isButtonsLocked=false;
          this.cdRef.detectChanges();
        }
      });
      this._router.events.pipe(
        filter((e:any): e is NavigationEnd => e instanceof NavigationEnd),
        map((e:any) => {
            return e.url == "/dashboard"
        }
        )
       )
      .subscribe((event: any) => 
       {
       
        if(event)
        {
          if(this._signalrService.state === HubConnectionState.Connecting)
          this.isConnecting$.next(false);
        }
       });
      // _router.events.subscribe((event: any) => {
      // //   filter((e:any): e is NavigationEnd => e instanceof NavigationEnd),
      // //   map((e:any) => {
      // //     this._loaderservices.hide();
      // //     if(e.url=="/dashboard")
      // //     this.setMemoryState();
      // //   })
      //   if (event instanceof NavigationStart) {
      //     this._loaderservices.show();
      //   } else if (event instanceof NavigationEnd) {
      //     this._loaderservices.hide();
      //     if(event.url=="/dashboard")
      //     {
      //       this.setMemoryState();
      //       if(this._signalrService.state === HubConnectionState.Connecting)
      //       this.isConnecting$.next(false);
      //     }
      //   }
      // });
  }

  ngOnInit() {
    this._msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
    )
    .subscribe(() => {
      if(!this.loginDisplay)
      this.loginDisplay =true;
    });
    this._msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this._authService.instance.setActiveAccount(payload.account);
        this.setLoginDisplay();
      });

    this._msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => {return (status === InteractionStatus.None||  status === InteractionStatus.HandleRedirect)}
      ))
      .subscribe(() => {
        this.setLoginDisplay();
      });
    this._apiTokenService.apiRole$.subscribe((role:IApiState) => {
      const jwt:IJWT|null =this._jwthelperService.getBasicProps(this._apiTokenService.token);
      if(jwt!==null)
      {
        this.ip=jwt.ip;
        this.email=jwt.email;
        this.tokenExp=jwt.isexp;
        this.tokenExpDate= jwt.exp;
      }
      if(this.tokenExpDate!==null && this.tokenExpDate!==undefined)
      { 
       const datetext = this._datePipe.transform(this.tokenExpDate.toUTCString(), 'dd-MM-yyyy HH:mm:ss',undefined, "nl-Nl");
       if(datetext!=null)
       this.datetext$ = of(datetext);
       }
      //        this.ip = this._jwthelperService.getTokenIPadrr( this._apiTokenService.token);
      // this.email = this._jwthelperService.getTokenEmail(this._apiTokenService.token);
      // this.tokenExp = this._jwthelperService.isTokenExpired(this._apiTokenService.token);
      // this.tokenExpDate = this._jwthelperService.getTokenExpirationDate(this._apiTokenService.token);
      this.setStatMax();
    });
    if(this._authservices.isLoggedIn() ) {
      this._apiTokenService.getRoleFromApiToken(false);
     // this.setMemoryState();
            /// Ontvangen van de data van de screen
      this._signalrService.hubLink.subscribe((data: IRelay|null) => {
        if(data!==null && data!==undefined && data.uid == this.email)
        {
           this.setRelaySubscription(data);
           if(this.firstcall==true)
            {
              this.firstcall=false;
              this._signalrService.connection.invoke("Relays","DUMP");
            }
          // this.setMemoryState();
          this._loaderservices.hide();
        }
        else
        this._loaderservices.hide();
      });  
    }
    else{
      this._loaderservices.hide();
      this._router.navigate(['login']);
    }
  }
  setLoginDisplay() {
    this.loginDisplay = this._authService.instance.getAllAccounts().length > 0;
    this.loginDisplay$ = of(this.loginDisplay);
  }

  private setRelaySubscription(element: IRelay) {
    if (element.r1 != null) {
      const r1 = element.r1 == RelayState.On ? true : false;
      if(r1!=this.aanUit)
      this.SubjectR1.next(r1);
    }
    if (element.r2 != null) {
      const r2 = element.r2 == RelayState.On ? true : false;
      if(r2!=this.opNeer)
      this.SubjectR2.next(r2);
    }
    if (element.r3 != null) {
      const r3 = element.r3 == RelayState.On ? true : false;

      if(r3!=this.bit1)
      this.SubjectR3.next(r3);
    }
    if (element.r4 != null) {
      const r4 = element.r4 == RelayState.On ? true : false;
      if(r4!=this.bit2)
      this.SubjectR4.next(r4);
    }
    if (element.r5 != null) {
      const r5 = element.r5 == RelayState.On ? true : false;
      if(r5!=this.bit3)
      this.SubjectR5.next(r5);
    }
    if (element.r6 != null) {
      const r6 = element.r6 == RelayState.On ? true : false;
      if(r6!=this.bit4)
      this.SubjectR6.next(r6);
    }
    if (element.r7 != null) {
      const r7 = element.r7 == RelayState.On ? true : false;
      if(r7!=this.bit5)
      this.SubjectR7.next(r7);
    }
    if (element.r8 != null) {
      const r8 = element.r8 == RelayState.On ? true : false;
      if(r8!=this.bit6)
      this.SubjectR8.next(r8);
    }
  }

  private setMemoryState() {
    this._dataService.dump().subscribe((data: IRelay[]) => {
      if (data != null) {
        //Inlezen opgeslagen data
        this.setRelaySubscription(data[0]);
      }
    });
  }

// Binaire waarden ommzetten naar decimale waarden
  private ConvertInComingDataSignalR() {
    let binnr = `${this.bit6?1:0}${this.bit5?1:0}${this.bit4?1:0}${this.bit3?1:0}${this.bit2?1:0}${this.bit1?1:0}`;
   
    this.stateMen = parseInt(binnr, 2);
    if(this.state==-1)
    this.isConnecting$.next(false);
    //slider zetten
    if (this.stateMen >= 0) {
          this.state = this.stateMen;
          this.SubjectSlider.next(this.state);
    }
  }


// Bepalen wat de maximale rol uit tijd moet zijn
  private setStatMax() {
    this.location = 'Koekoek';
    if (this.email === 'johan@0251.eu')
    {
       this.stateMax = this._configService.get('roloutTime43');
       this.location = 'Koning'
    }
    else {
      this.stateMax = this._configService.get('roloutTime21');
    }
  }

  ngAfterViewInit() {

    const mobile = isMobile();
    const eventdown = mobile?'touchstart':'mousedown';
    const eventup =mobile?'touchend':'mouseup';
    // this.aanUit ? this.r1color="warn" : this.r1color="primary";
    // this.opNeer ? this.r2color="warn" : this.r2color="primary";
    this.globalInstanceDown = this.renderer.listen(this.btnDown.nativeElement, eventdown, () => {
      this.isButtonPressed = true;
      this.renderer.setStyle(this.btnDown.nativeElement, 'color', 'green');
      this.testToken();
      this.timerIntervalDown = interval(1_000).subscribe(()=>{
        if( this.aanUit  && this.opNeer && this.state< this.stateMax)
        {
          this.state =this.state+1;
          // Scherm neer stand wegschijven naar relay geheugen
          this.setMemory(this.state);
        }
        else if(this.state< this.stateMax){
          this.screenDown();
          this.screenOn();
          
        }
        else if(this.state=== this.stateMax){
          this.ScreenOff();
          this.renderer.setStyle(this.btnDown.nativeElement, 'color', 'black');
        }
      });
    });
    this.globalInstanceDownUp = this.renderer.listen(this.btnDown.nativeElement, eventup, () => {
        this.timerIntervalDown.unsubscribe();
        this.isButtonPressed = false;
        // Scherm uitzetten
        this.ScreenOff();
        this.renderer.setStyle(this.btnDown.nativeElement, 'color', 'black');
    });

    this.globalInstanceUpDown = this.renderer.listen(this.btnUp.nativeElement, eventdown, () => {
      this.isButtonPressed = true;
      this.renderer.setStyle(this.btnUp.nativeElement, 'color', 'green');
      this.testToken().then(() => {
        this.timerIntervalUp = interval(1_000).subscribe(()=>{
          if( this.aanUit  && !this.opNeer && this.state>0)
          {
            this.state =this.state-1;
          // Screen up
          this.screenUp(true);
          this.setMemory(this.state);
          }
          else if(this.state>0){
            this.screenUp(true);
          }
        });
          if(this.state===0){
            if(!this.aanUit)
            {
            this.timerIntervalUp.unsubscribe();
            this.screenUp(true);
            const delay_observable = of('').pipe(delay( 2000 ));
            delay_observable.subscribe(() => {
              this.ScreenOff();
            });
          }
        }

      });

    });
    this.globalInstanceUpUp = this.renderer.listen(this.btnUp.nativeElement, eventup, () => {
      this.testToken().then(() => {
              // Extra uptime to be sure the screen is closed
      if(this.state ==0)
        {
          setTimeout(() => { this.ScreenOff(); }, 5000);
        }
        else
        {
          this.ScreenOff();
        }
        this.timerIntervalUp.unsubscribe();
        this.isButtonPressed = false;
        this.renderer.setStyle(this.btnUp.nativeElement, 'color', 'black');
      });

    });
 }
  private testToken(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (
        this._apiTokenService.token != null &&
        this._apiTokenService.token != '' &&
        this._apiTokenService.token != undefined
      ) {
        this.tokenExp = this._jwthelperService.isTokenExpired(this._apiTokenService.token);
        if (this.tokenExp) {
          this._apiTokenService.getRoleFromApiToken(false);
        }
        resolve();
      } else {
        this._authservices.startAuthentication();
        reject();
      }
    });
  }

 // Screen Down
 // If relay 2 (Up/down) is not on then set in ON
  private screenDown() {
    if (!this.opNeer) {
      this._signalrService.connection.invoke("RelayOnOrOff",`L2`);
    }
  }
  private screenUp(isOn:boolean) {
    if (this.opNeer) {
      this._signalrService.connection.invoke("RelayOnOrOff",`D2`);

    }
    // Scherm aanzetten
    if(isOn)
    this.screenOn();
  }

  private screenOn() {
    if (!this.aanUit) {
      
      this._signalrService.connection.invoke("RelayOnOrOff",`L1`);
    }
 }
  private ScreenOff() {
    if (this.aanUit) {
      this._signalrService.connection.invoke("RelayOnOrOff",`D1`);
    }
    if (this.opNeer) {
      this._signalrService.connection.invoke("RelayOnOrOff",`D2`);
    }
  }


  lightStyle(state: any) {
    // if(state.on) {
    //   let bri = Math.floor(state.bri / 3) + 150
    //   return `rgb(${bri}, ${bri}, 100)`
    // }
    return "rgb(254,254,254)"
  }
  // Wegschrijven naar relay geheugen
  private setMemory(state:number)
  {
    if (state < 0) {
      state = 0xFFFFFFFF + state + 1;
     }
     this.setRelayStateNew(state,5,8)
     this.setRelayStateNew(state,4,7);
     this.setRelayStateNew(state,3,6);
     this.setRelayStateNew(state,2,5);
     this.setRelayStateNew(state,1,4);
     this.setRelayStateNew(state,0,3);
  }

 // Binary State of relay
 private setRelayStateNew(state:number,bitIndex:any,relaynr:number)
 {
   // Moet het relay een andere status krijgen?
   let change:boolean=false;
   // Kijken of bit 1 of 0 moet zijn
   const bitnummer:boolean= this.findBitNumber(state,bitIndex) ==1 ? true : false;
   switch (relaynr) {
     case 8:// Als de huidge waarde anders is dan de change flag zetten en org waarde bij werken
      if( this.bit6!=bitnummer)
      {
        change=true;
      }
       break;
     case 7:
      if( this.bit5!=bitnummer)
      {
        change=true;
      }
       break;
     case 6:
      if( this.bit4!=bitnummer)
      {
        change=true;
      }
       break;
     case 5:
      if( this.bit3!=bitnummer)
      {
        change=true;
      }
       break;
     case 4:
      if( this.bit2!=bitnummer)
      {
        change=true;
      }
       break;
      break;
     case 3:
      if( this.bit1!=bitnummer)
      {
        change=true;
      }
       break;
     default:
       break;
   }

   if(change)
   {
     if(bitnummer)
     {
       this._signalrService.connection.invoke("RelayOnOrOff",`L${relaynr}`)
     }
     else{

       this._signalrService.connection.invoke("RelayOnOrOff",`D${relaynr}`)
     }
   }
 }
  private findBitNumber(n:number, bitIndex:any)
  {
    const bitMask = 1 << bitIndex;
    const result = n & bitMask;
    return result >>> bitIndex;
  }


  
  public containter_click(event:any) {
    if(this.isButtonsLocked) {
      event.stopPropagation()
  }

  }
  ngOnDestroy() {
    //  this.globalInstanceDown();
    // this.globalInstanceUpDown()
    // this.globalInstanceUpUp();
    // this.globalInstanceUp();
  }
}
