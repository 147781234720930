import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {  LOCALE_ID, NgModule } from '@angular/core';
import { APP_BASE_HREF, registerLocaleData} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { AppComponent } from './app.component';
import { WindowRef } from './window-ref';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { PageNotFoundComponent } from './pages/page-not-found.component';
import { SharedModule } from './shared/shared.module';


import { LoaderComponent } from './components/Loader/loader.component';
import { LoaderService } from './components/Loader/loader.service';
import { ErrorInterceptor } from './core/error.interceptor';
import { JwtHelperService } from './core/jwthelper.service';
//import { QueueRequestService } from './core/services/queue-request.service';
// import { QueueInterceptor } from './core/services/queue-interceptor';
//import { WebsocketService } from './core/services/websocket/websocket.service';
import { MessageService } from 'primeng/api';

import { LoadingInterceptorService } from './core/services/loading.interceptor.service';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { SignalrService } from './core/services/signalr/SignalrService';
import { MSALInterceptorConfigFactory } from './core/auth/msal-interceptor-config-factory';
import { InteractionType } from '@azure/msal-browser';
import { MSALInstanceFactory } from './core/auth/msal-instance-factory';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiTokenService } from './core/services/apitoken/api-token.service';
import { HeartBeatComponent } from './components/heartbeat/heart-beat.component';
import {  TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeNl);
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoaderComponent
    ,HeartBeatComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    ServiceWorkerModule.register('/ngsw-worker.js'),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  providers: [

   {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useValue: {
      interactionType: InteractionType.Redirect
    } as MsalGuardConfiguration
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptorService,
    multi: true
  },
  MsalGuard,
  MsalService,
  MsalBroadcastService,
    LoaderService,
    JwtHelperService,
    ApiTokenService,
    TranslateService,
    SignalrService,
    MessageService,
    WindowRef,{provide: APP_BASE_HREF, useValue: '/' },
    {provide: LOCALE_ID, useValue: 'nl-Nl' },
    
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true, },
    // QueueRequestService,
    // { provide: HTTP_INTERCEPTORS, useClass: QueueInterceptor, multi: true },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({registrationStrategy: 'registerImmediately',scope:'/',enabled: environment.production}),
    }
  ],
  bootstrap: [AppComponent,MsalRedirectComponent]
})
export class AppModule { }
