import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-404',
  template: `
    <article class="animated slideInRight">
      <h4>Ooop!</h4>
      <div>Deze pagina is niet gevonden.</div>
    </article>
  `
})
export class PageNotFoundComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }
}
