import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { IApiState } from './Iapistate';

import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';
import { JwtHelperService } from '../../jwthelper.service';
import { SignalrService } from '../signalr/SignalrService';
import { HubConnectionState } from '@microsoft/signalr';

@Injectable()
export class ApiTokenService {
  private apiSubject = new Subject<IApiState>();
  apiRole$:Observable<IApiState>;
  endpoint!:string;
  roles: string[]=[];
  token!: string;
  userData: any;
  userRoleData: any;

  constructor(
     private _authService: MsalService,
    private _router: Router,
    private _jwthelper:JwtHelperService,
    private _signalrService: SignalrService
  ) {
      this.apiRole$ = this.apiSubject.asObservable();
  }

  // Ophalen van api token  en opslaan na succes volle inlog en daarna de rol bepalen
  getApiToken(){

      this.getRoleFromApiToken(true);
      this._router.navigate(['dashboard']);
  }

  getRoleFromApiToken(roles:boolean) {
    const scopes = [environment.resources.zonneschermApi.resourceScope];
    const account = this._authService.instance.getAllAccounts().filter( f=> { return f.tenantId===environment.auth.tenantId})[0];
    if(account==undefined)
    {
      this._authService.loginRedirect({scopes});
      return;
    }
    this._authService.acquireTokenSilent({scopes, account}).toPromise().then((response: any): void  => {
      this.token= response.accessToken;
            }).finally( ()=>{
              let apiState:IApiState={
                hasToken:false,
                isAdmin: false,
                isTester:false,
                token:this.token
              };
              if(this.token!== undefined)
              {
                 apiState.hasToken=true;
                if(this._jwthelper.getGroups(this.token))
                {
                  this.userData = <string[]><unknown>this._jwthelper.getGroups(this.token);
                  const expectedGroup = [environment.groups.groupAdmin,environment.groups.groupTester];

                  if(this.testGroup(expectedGroup)) {

                     if( this._signalrService.connection!==undefined && this._signalrService.connection.state===HubConnectionState.Connected)
                     {
                      this._signalrService.connection.stop().then(()=>{ this._signalrService.initiateSignalrConnection(this.token);
                        console.log("Signalr connection stopped and started again");
                      });
                     
                     }
                     else if(this._signalrService.connection!==undefined && this._signalrService.connection.state!==HubConnectionState.Connected && this._signalrService.connection.state!==HubConnectionState.Connecting  && this._signalrService.connection.state!==HubConnectionState.Reconnecting)
                    {
                       this._signalrService.initiateSignalrConnection(this.token);
                      }

                    if(this.testGroup([environment.groups.groupAdmin]))
                    {
                      apiState.isAdmin=true;
                    }
                    else
                    {
                      apiState.isTester=true;
                    }
                  }
                }
                if(this._jwthelper.getRoles(this.token))
                {
                  
                  this.userRoleData = <string[]><unknown>this._jwthelper.getRoles(this.token);
                  const expectedRole = [environment.roles.roleAdmin,environment.roles.roleTester];
                  if(this.testRole(expectedRole)) {
                    apiState.hasToken=true;
                  }

                     if( this._signalrService.connection!==undefined && this._signalrService.connection.state===HubConnectionState.Connected)
                     {
                      this._signalrService.connection.stop();
                      this._signalrService.initiateSignalrConnection(this.token);

                     }
                     else
                     this._signalrService.initiateSignalrConnection(this.token);
                     if(this.testRole([environment.roles.roleAdmin]))
                     {
                       apiState.isAdmin=true;
                     }
                    else
                    {
                      apiState.isTester=true;
                    }
                  }
              }
              this.apiSubject.next(apiState);
            }
          );
      }
      private testGroup(expectedGroup: any):boolean {
        let hasGroup = false;

        expectedGroup.forEach((element:any) => {
          if(!hasGroup)
          {hasGroup =this.userData.includes(element);}
        });
        return hasGroup;
      }

      
      private testRole(expectedRole: any):boolean {
        if(expectedRole==null)
        {
          return false;
        }
        let hasRole = false;

        expectedRole.forEach((element:any) => {
          if(!hasRole)
          {hasRole =this.userRoleData.includes(element);}
        });
        return hasRole;
      }
}
