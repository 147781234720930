<p-fieldset legend="Zonnescherm - Uitloggen">
    <div id="llogout" *ngIf="isAuthenticated">
          <p>Je bent ingelogd met een <B>NIET</B> 0251 account maar met het volgende account:</p>
          <p style="text-align: center;"><b>{{username}}</b></p>
        <p>Klik op de Uitloggen knop en meldt je eerst af met je {{username}} account.</p>
        <p>En log hierna in met je 0251 account.</p>
        <p  style="text-align: center;">
          <button pButton label="Uitloggen" (click)="logout()" title="logout"></button>
        </p>
    </div>
    </p-fieldset>