import { Injectable } from '@angular/core';
import { ILoaderState } from './ILoader';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {
    private loaderSubject = new Subject<ILoaderState>();
    private loaderdebounceSubject = new Subject<ILoaderState>();
    loaderdebounceState$ = this.loaderdebounceSubject.pipe(debounceTime(500));//.asObservable();
    loaderState$ = this.loaderSubject.pipe(debounceTime(50));//.asObservable();
    constructor() { }

    show():void {
      this.loaderSubject.next(<ILoaderState>{show: true});
    }
    showDebounce():void {
      this.loaderdebounceSubject.next(<ILoaderState>{show: true});
    }

    hide():void {
      this.loaderSubject.next(<ILoaderState>{show: false});
    }
}
