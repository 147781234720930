import { Component, ViewChild,  OnInit, ApplicationRef,  HostListener } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../environments/environment';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';
import { interval, concat, timer, Subject } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { Message, MessageService } from 'primeng/api';
import { WindowRef } from './window-ref';
import { IiOsInfo } from './core/model/ios-info.interface';
import { iOS } from './helper/ios-info';
import { SignalrService } from './core/services/signalr/SignalrService';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import * as signalR from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('onoffline',{static: true})

    TITLE = 'Zonnescherm afstandbediening';
    deferredPrompt: any;
    first: boolean=false;
    message!:Message;
    isiproduct: boolean=false;
    subcriptionsw: any;
    isinstall: boolean=false;
    updateavailable!:string;
    INSTALLNOW!:string;
    currentversion!:string;
    newversion!:string;
    lastbg: any;
    private readonly _destroying$ = new Subject<void>();
    isIOS:Boolean=false;
  YES: string="Ja";
  NO: string="Nee";
  CONNECTED: any;
  DISCONNECTED: any;
  ONLINE: any;
  OFFLINE: any;
  DONTASK: any;
  MESSSAGETAP2IPAD: any;
    constructor(
      private winRef: WindowRef,
      private _metaService: Meta,
      private _titleService:Title,
      private _messageService: MessageService,
      private swUpdate: SwUpdate,
      public snackBar: MatSnackBar,
      private appRef: ApplicationRef,
      private _signalrService: SignalrService,
      private _msalBroadcastService: MsalBroadcastService,
      private _authService: MsalService,
      private _translateService:TranslateService,
      )
    {
      //this.logServices.buildPublisher();
    }
    ngOnInit(): void {
      this._msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })
      this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => (
          {
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))).subscribe((evt) => {

          try{
            const regexp = new RegExp('^(\\d{4})(\\d{2})(\\d{2}).(\\d+)$');
            let versioncurrent ="";
            if(evt.current.appData!==undefined)
            {
              let { changelog}:any = evt.current.appData;
              versioncurrent =`${changelog}`.replace('versie','Huidige versie');

            }
            if(evt.available.appData!==undefined)
            {
              let { changelog ,changeDate, changeType}:any = evt.available.appData;

              const result = changeDate.match(regexp);
              let version ="";
              if(result && result.length>3)
              {
                version =`${result[3]}-${result[2]}-${result[1]} | build ${result[4]}\n\n`;
                this.winRef.nativeWindow.localStorage.setItem("version",`${changelog}${version}`);
                this.winRef.nativeWindow.localStorage.setItem("hash",`${evt.available.hash}`);
              }
              this._messageService.clear();
              this._messageService.add({key: 'update', sticky: true, severity:'warn', summary:'Er is een update beschikbaar', detail:`${changeType}${versioncurrent}${changelog.replace('versie','Nieuw versie')}${version} Install Nu`});
            }
          }
          catch(e)
          { console.log(e);};
      });
      const appIsStable$ = this.appRef.isStable.pipe(first(stable => stable)
      ,tap(() =>
         {
          console.log('App is stable now');
          // if( this._signalrService.state!=signalR.HubConnectionState.Connected && this._signalrService.state!=signalR.HubConnectionState.Connecting)
          // this._signalrService.initiateSignalrConnection();
          // if(!this._websocketService.connected)
          // {
          //  this._websocketService.connect();
          // }
           this.cleanUpCache();
          }
        )
      );
      const everySixHours$ = interval( 3600 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
      everySixHoursOnceAppIsStable$.subscribe(() => {
        if(environment.production && this.swUpdate.isEnabled)
            this.swUpdate.checkForUpdate();
      });
      //Bepalen tonen installatie scherm
      this.lastbg = this.winRef.nativeWindow.localStorage.getItem("installfirst");
      if(this.lastbg!==null)
      {
        if(+this.lastbg===1)
        {
          this.winRef.nativeWindow.localStorage.setItem("installfirst","2");

        }
        if(+this.lastbg===2)
        {
          this.winRef.nativeWindow.localStorage.setItem("installfirst","3");

        }
        if(+this.lastbg>=3)
        {
          this.first =true;
        }
      }
      else{
        
          this.winRef.nativeWindow.localStorage.setItem("installfirst","1");
          const isIOS = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test( userAgent );
          }
           this.isIOS =isIOS();

      }
      this._translateService.addLangs(['en', 'nl']);
      this._translateService.setDefaultLang('nl');
      const browserLang = this._translateService.getBrowserLang();
      this._translateService.use(browserLang?.match(/en|nl/) ? browserLang : 'en');
      // this._signalrService.stateWithPropertyChanges
      // .pipe
      // (
      //   map(state => state && state.stateChanges.counter),
      //   distinctUntilChanged()
      // )
      // .subscribe(state => {
      //   if (state) {
      //     this.counter$ = of( state);
      //   }
      // });

      if (window.addEventListener ) {
        /*
            Works well in Firefox and Opera with the
            Work Offline option in the File menu.
            Pulling the ethernet cable doesn't seem to trigger it.
            Later Google Chrome and Safari seem to trigger it well
        */
        window.addEventListener("online", () =>{this.isOnline()}, false);
        window.addEventListener("offline", () =>{this.isOffline()} , false);
      }
      if(!this.first && this.swUpdate.isEnabled)
      {
        this.InstallBrowserApp();
        this.showInstallForiOS();
      }
      // this. createLink();
      this.GetMetaText();
      this.GetLanguageText();
    }

    // private createLink() {
    //   let link: HTMLLinkElement = this.doc.createElement('link');
    //   link.setAttribute('rel', 'dns-prefetch');
    //   link.setAttribute('href', `https://${environment.domainapi}`);
    //   this.doc.head.appendChild(link);
    // }
    private showInstallForiOS() {

      const userAgent = window.navigator.userAgent.toLowerCase();
      let { isIos, os16_4_1_or_higher, isSafari ,deviceType}:IiOsInfo = iOS.iOsInfo(userAgent);
      if (isIos) {
        if (!this.winRef.standalone && isSafari && deviceType!=="ipad") {
          timer(10_000, 3600_000).subscribe(n=> this.isinstall = true);
        }
        else if (!this.winRef.standalone && isSafari) {
          this.showInstalPWA(deviceType );
        }
      }
    }
    private showInstalPWA(deviceType:string) {
      this._messageService.clear();
      this.isiproduct=true;
      if(deviceType==="ipad"){
      this.message.data=this.MESSSAGETAP2IPAD;
      this._messageService.add(this.message);
      }
    }
    private InstallBrowserApp() {

      if ((navigator as any).standalone == undefined) {
        // It's not iOS
        if (window.matchMedia('(display-mode: browser)').matches) {
            // We are in the browser
            window.addEventListener('beforeinstallprompt', event => {
              // prevent the mini-infobar by calling
                event.preventDefault();
                this.deferredPrompt = event;
                this._messageService.clear();
                this._messageService.add({key: 'install', sticky: true, severity:'', summary:this.message.summary, detail:this.message.detail});
                return false;
            });
        }
      }
    }
    private isOnline() {
      this._messageService.clear();
      this._messageService.add({key:"onoff" ,severity:'success', summary: 'Online', detail:'Verbonden'});
    }
    private isOffline() {
      this._messageService.clear();
      this._messageService.add({key:"onoff" ,severity:'warn', summary: 'Offline', detail:'Geen verbinding'});
    }

    private cleanUpCache() {
      try{
        caches.keys().then((cacheNames:string[]) => {
          return Promise.all(
            cacheNames.filter(cacheName =>
                 cacheName.indexOf(`ngsw:/:${this.winRef.nativeWindow.localStorage.getItem("hash")}`)==-1 &&
                 cacheName.indexOf(`ngsw:/:db:${this.winRef.nativeWindow.localStorage.getItem("hash")}`)==-1 &&
                 cacheName.indexOf('control')==-1 &&
                cacheName.indexOf('api')==-1
              // Return true if you want to remove this cache,
              // but remember that caches are shared across
              // the whole origin
            ).map(cacheName =>{
              return caches.delete(cacheName);
            })
          );
        });
      }
      catch(e){
        console.log(e);
      };
    }
    onConfirm()
    {
        if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((result:any) => {
          if (result.outcome == 'dismissed') {
            //this._insightService.logEvent("PWA",{"install":"It was NOT installed"});
          } else {
            //this._insightService.logEvent("PWA",{"install":"It was installed"});
          }
          this.deferredPrompt = null;
        });
      }
    }
    onReject() {
      this.winRef.nativeWindow.localStorage.setItem("installfirst","3");
      //this.cookies.set("iOsShowInstall","0",90);
      this._messageService.clear('install');
    }
    onRejectUpdate(){
      this._messageService.clear('update');
    }
    onConfirmUpdate(){
      this._messageService.clear('update');
      this.swUpdate.activateUpdate().then(event => {
        location.reload();
      })
    }

    setLoginDisplay() {
      // this.loginDisplay = this._authService.instance.getAllAccounts().length > 0;
    }

    checkAndSetActiveAccount(){
      /**
       * If no active account set but there are accounts signed in, sets first account to active account
       * To use active account set here, subscribe to inProgress$ first in your component
       * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
       */
      const activeAccount = this._authService.instance.getActiveAccount();
  
      if (!activeAccount && this._authService.instance.getAllAccounts().length > 0) {
        const accounts = this._authService.instance.getAllAccounts();
        this._authService.instance.setActiveAccount(accounts[0]);
      }
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event: Event) {
    this._signalrService.stopSignalrConnection();
}
    private GetMetaText() {
      let logourl = `https://${environment.domain}/assets/img/logo.jpg`;
    this._metaService.addTags([

      {name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0'},
      {name: 'description', content:this.TITLE},
      {name: 'theme-color', content:'#FFCC00'},
      {name: 'robots', content: 'INDEX, FOLLOW'},
      {name: 'author', content: 'Meteo Beverwijk'},
      {name: 'publisher', content: 'Meteo Beverwijk, koningstraat 192, 1941BG Beverwijk'},
      {name: 'keywords', content: 'Zonnescherm'},
      {name: 'apple-mobile-web-app-capable', content:'yes'},
      {name: 'apple-mobile-web-app-status-bar-style', content:'translucent'},
      {name: 'msapplication-TileColor', content:'#ffffff'},
      {name: 'msapplication-TileImage', content:'/ms-icon-144x144.png'},
      {name: 'msapplication-config', content:'/browserconfig.xml'},
      {httpEquiv: 'Content-Type', content: 'text/html'},
      {property: 'og:title',content:this.TITLE},
      {property: 'og:description', content:this.TITLE},
      {property: 'og:url', content:`https://${environment.domain}`},
      {property: 'og:image', content: logourl},
      {property: 'og:image:secure_url', content: logourl},
      {property: 'og:image:url', content:logourl},
      {property: 'og:image:type', content:'image/jpeg'},
      {property: 'og:type', content: "website"},
      {name: 'date', content: '2020-06-11', scheme: 'YYYY-MM-DD'},
      {charset: 'UTF-8'}
    ]);
   }

   private setvalues(app: any,connect: any,install: any) {
    this.TITLE =app['TITLE'];
    this._titleService.setTitle(this.TITLE);
    this.YES =app['YES'];
    this.NO =app['NO'];
    this.CONNECTED=connect['connected'];
    this.DISCONNECTED=connect['disconnected'];
    this.ONLINE=connect['online'];
    this.OFFLINE=connect['offline'];
    this.INSTALLNOW =install['installnow'];
    this.updateavailable =install['updateavailable'];
    this.currentversion =install['CURRENTVERSION'];
    this.newversion =install['NEWVERSION'];

    this.DONTASK =install['DONTASK'];

    this.MESSSAGETAP2IPAD =install['tap3'];

    this.message ={key: 'install', sticky: true, severity:install['tap1'],
    summary:install['summary'],
    detail:install['detail'],
    data:install['tap2']
   };
   if(!this.isIOS){
    this.TITLE =app['TITLE'];
    this._titleService.setTitle(this.TITLE);
    }
    // let logourl = `https://${environment.domain}/assets/img/logo.jpg`;
    // const dn = new Date();
    // // adjust 0 before single digit date
    // let date = ("0" + dn.getDate()).slice(-2);
    // let month = ("0" + (dn.getMonth() + 1)).slice(-2);
    // let year = dn.getFullYear();

    //    this.metaService.addTags(
    //      [
    //        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
    //        {name: 'description', content:this.TITLE},
    //        {name: 'theme-color', content:'#B41313'},
    //        {name: 'robots', content: 'INDEX, FOLLOW'},
    //        {name: 'author', content: 'Meteo Beverwijk'},
    //        {name: 'publisher', content: 'Filmfest Ballmertshofen, Germany'},
    //        {name: 'keywords', content: 'Ballmertshofer Filmfest,Filmfest,Filmfestkuh,Bob Dylan,Ballmertshofen'},
    //        {name: 'apple-mobile-web-app-capable', content:'yes'},
    //        {name: 'apple-mobile-web-app-status-bar-style', content:'translucent'},
    //        {name: 'msapplication-TileColor', content:'#ffffff'},
    //        {name: 'msapplication-TileImage', content:'/ms-icon-144x144.png'},
    //        {name: 'msapplication-config', content:'/browserconfig.xml'},
    //        {httpEquiv: 'Content-Type', content: 'text/html'},
    //        {property: 'og:title',content:this.TITLE},
    //        {property: 'og:description', content:this.TITLE},
    //        {property: 'og:url', content:`https://${environment.domain}`},
    //        {property: 'og:image', content: logourl},
    //        {property: 'og:image:secure_url', content: logourl},
    //        {property: 'og:image:url', content:logourl},
    //        {property: 'og:image:type', content:'image/jpeg'},
    //        {property: 'og:type', content: "website"},
    //        {content:app['TITLE']},
    //        {name: 'date', content: `${year}-${month}-${date}`, scheme: 'YYYY-MM-DD'},
    //        {charset: 'UTF-8'}
    //      ]);

  }
   private GetLanguageText() {

    const data = this._translateService.instant('APP');
    const connect = this._translateService.instant('CONNECT');
    const install = this._translateService.instant('INSTALL');
    if(typeof data !== 'string')
    this.setvalues(data,connect,install);

  }
  }