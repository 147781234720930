<mat-sidenav-container class="mat-typography" fullscreen>
  <mat-sidenav #sidenav mode="side" >
          <mat-nav-list>
            <mat-list-item>
              <a matLine [routerLink]="['/dashboard']" 
               routerLinkActive="active" (click)="sidenav.close()">Dashboard</a>
            </mat-list-item>
            <mat-divider></mat-divider>
            <h3 matSubheader>App</h3>
            <mat-list-item>
                <a matLine [routerLink]="['/controls/update']"
                routerLinkActive="active" (click)="sidenav.close()">Update</a>
              </mat-list-item>
              <mat-list-item>
                <a matLine [routerLink]="['/controls/push']"
                routerLinkActive="active" (click)="sidenav.close()">Push</a>
              </mat-list-item>
          </mat-nav-list>
      </mat-sidenav>
<mat-toolbar color="primary">
  <span>
      <button pButton type="button" icon="pi pi-bars" (click)="sidenav.toggle()" title="sidebar"></button>
  </span>
  <app-heart-beat style="position: relative;margin-right: 5px;"></app-heart-beat>
</mat-toolbar>
<div><router-outlet></router-outlet><angular-loader></angular-loader></div>
</mat-sidenav-container>

<p-toast position="center" key="center"></p-toast>
<p-toast position="top-right" key="onoff"></p-toast>

<p-toast id="update" position="bottom-center" key="update" (onClose)="onRejectUpdate()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div style="text-align: center">
            <i class="pi pi-exclamation-triangle" style="font-size:3em"></i>
            <h3>{{message.summary}}</h3>
            <p style="white-space: pre-line">{{message.detail}}</p>
        </div>
        <div class="install-button-container">
          <div class="install-button">
            <button type="button" pButton (click)="onRejectUpdate()" label="{{NO}}" class="ui-button-secondary"  title="{{NO}}"></button>
          </div>
          <div class="install-button">
              <button type="button" pButton (click)="onConfirmUpdate()" label="{{YES}}" class="ui-button-success" title="{{YES}}"></button>
          </div>
        </div>
    </ng-template>
  </p-toast>
<p-toast position="center" key="install" (onClose)="onReject()" [baseZIndex]="5000">
<ng-template let-message pTemplate="message">
    <div style="text-align:center">
        <i class="pi pi-exclamation-triangle" style="font-size:3em"></i>
        <h3>{{message.summary}}</h3>
        <p style="white-space: pre-line">{{message.detail}}</p>
        <p *ngIf="message.severity!==''" class="pwa-install">{{message.severity}} <svg class="pwa-install-icon" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Share</title><path fill="#007AFF" d="M48.883,22.992L61.146,10.677L61.146,78.282C61.146,80.005 62.285,81.149 64,81.149C65.715,81.149 66.854,80.005 66.854,78.282L66.854,10.677L79.117,22.992C79.693,23.57 80.256,23.853 81.114,23.853C81.971,23.853 82.534,23.57 83.11,22.992C84.25,21.848 84.25,20.125 83.11,18.981L65.997,1.794C65.715,1.511 65.421,1.215 65.139,1.215C64.563,0.932 63.718,0.932 62.861,1.215C62.579,1.498 62.285,1.498 62.003,1.794L44.89,18.981C43.75,20.125 43.75,21.848 44.89,22.992C46.029,24.149 47.744,24.149 48.883,22.992ZM103.936,35.32L81.114,35.32L81.114,41.053L103.936,41.053L103.936,121.27L24.064,121.27L24.064,41.053L46.886,41.053L46.886,35.32L24.064,35.32C20.928,35.32 18.355,37.904 18.355,41.053L18.355,121.27C18.355,124.419 20.928,127.003 24.064,127.003L103.936,127.003C107.072,127.003 109.645,124.419 109.645,121.27L109.645,41.053C109.645,37.891 107.072,35.32 103.936,35.32Z" /></svg> {{message.data}}</p>
    </div>
    <div class="ui-g ui-fluid">
      <div class="ui-g-6">
        <button pButton (click)="onReject()" label="{{DONTASK}}" class="ui-button-secondary" title="{{DONTASK}}"></button>
    </div>
        <div class="ui-g-6">
            <button *ngIf="!isiproduct" pButton (click)="onConfirm()" label="{{YES}}" class="ui-button-success" title="{{YES}}"></button>
        </div>

    </div>
</ng-template>
</p-toast>
<p-toast position="bottom-left" key="saveupdate"></p-toast>
