import { NgModule , Optional, SkipSelf, OnDestroy} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfigService } from './services/config.service';
import { PushService } from './services/api/push.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from '../components/dashboard.component';
import { AuthService } from './services/auth.services';

import { HeaderInterceptor } from './header.interceptor';
import { LoginComponent } from '../components/authorization/login/login.component';
import { LogoutComponent } from '../components/authorization/logout/logout.component';
import { DataService } from './services/api/data.service';
import { WebPushNotificationService } from './services/api/webpush-notification.services';
import { WebPushService } from './services/api/webpush-subscription.services';
import { AlertService } from './services/api/alert.service';
@NgModule({
    imports: [
      CommonModule,
      RouterModule,
      SharedModule

    ],
    exports: [
      LoginComponent,
     LogoutComponent
    ],
    declarations: [
      // KeysPipe,
      DashboardComponent, LoginComponent, LogoutComponent
    ],
    providers: [
   
      AlertService,
    DataService,
    ConfigService,
    PushService,
    AuthService,
    DatePipe,
    WebPushNotificationService,
    WebPushService,
    // ApiTokenService,


    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }
    ]
  })
  
  export class CoreModule implements OnDestroy {
    ngOnDestroy(): void {
     console.log("Opruimen Core");
    }
    constructor(
      @Optional()
      @SkipSelf()
      parentModule: CoreModule
    ) {
     throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
   }
