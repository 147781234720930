import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPushObject } from '../../model/pushsub.interface';
import { ConfigService } from '../config.service';
import { ResourceService } from './resource.service';
import { AlertService } from './alert.service';
@Injectable()
export class WebPushService extends ResourceService<IPushObject> {
  constructor(
    httpClient: HttpClient,
    _alertServices:AlertService,
    private configService: ConfigService) {
    super(
      httpClient,
      `${configService.apiUrl}`,
      _alertServices,
      'webpush'
      );
  }
}