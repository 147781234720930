import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  apiurl:string="-api";
  constructor(  ) {
    this.apiurl= environment.domainapi;
  }

  intercept( req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ( req.url.indexOf(environment.resources.zonneschermApi.resourceUri)===-1 || req.url.indexOf(this.apiurl+'/api/log')!==-1 ){
      return next.handle(req);
  }
    if (!req.headers.has('Content-Type')) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }
    if (!req.headers.has('If-None-Match')) {
      req = req.clone({ headers: req.headers.delete('If-None-Match') });
    }
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    return next.handle(req);
      }
}
