import { Component } from '@angular/core';


@Component({
  selector: 'mb-no-webpush',
  templateUrl: './no-webpush.component.html',
  styleUrls: ['./no-webpush.component.css']
})
export class NoWebpushComponent {
  constructor( ) { }

 
}