export class DateConvert {
    public static Deserialize(data: any): any
    {
        return JSON.parse(data, DateConvert.ReviveDateTime);
    }
    private static ReviveDateTime(key: any, value: any): Date 
    {
        if (typeof value === 'string')
        {
            let a = /\/Date\((\d*\+0[1|2]00)\)\//.exec(value);
            if (a)
            {
                let t =a[1].replace('+0100','').replace('+0200','')
                return new Date(+t);//Het plusje maakt er een nummer van
            }
        }
        return value;
    }
}
